import React, { useState,useEffect } from "react";
import "../Styles/SelfService.css";
import { apiUrl } from "../Pages/Config";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";

const PasswordSetup = () => {
  
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path === "/login") {
      localStorage.removeItem('Login')
    } 
  }, []);

  const accessToken = localStorage.getItem('accessToken');

  const validateForm = () => {
    const errors = {};
    if (!password) errors.password = "New Password required";
    if (password.length < 8) errors.password = "8 characters minimum required";
    if (confirmpassword.length < 8) errors.password = "8 characters minimum required";
    if (!confirmpassword) errors.confirmpassword = "Confirm Password required";
    if(password !== confirmpassword) errors.confirmpassword = "Passwords must be same"
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  
  const getUserDetails = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    try {
      const response = await fetch(apiUrl + "updateSspUserPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          customer_name: localStorage.getItem('username'),
          customer_password: password,
        }),
      });
  
      const UserData = await response.json();      
      if (UserData.status) {
        toast.success(UserData?.message, {
          position: "top-right",
          autoClose: 3000,
        });  
        navigate('/login');
        localStorage.removeItem('username')
      }else {
        toast.warn(UserData?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setConfirmPassword("");
        setPassword("");
      }
    } catch (error) {
      toast.error(error?.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setConfirmPassword("");
      setPassword("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getUserDetails(event);
    }
  };

  return (
    <div className="container mt-4" id="login-container">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card" style={{ padding: "0" }}>
            <div
              className="card-header text-center"
              style={{ padding: "1rem" }}
            >
              <strong>Setup a new Password</strong>
            </div>
            <div className="card-body text-left">
              <form onSubmit={getUserDetails}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    value={localStorage.getItem('username')}
                    disabled
                  />
                  {formErrors.name && (
                    <span style={{ color: "#FF0000" }}>{formErrors.name}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="username">New Password  <span className="text-danger">*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.password && (
                    <span style={{ color: "#FF0000" }}>{formErrors.password}</span>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="password">Confirm Password <span className="text-danger">*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.confirmpassword && (
                    <span style={{ color: "#FF0000" }}>
                      {formErrors.confirmpassword}
                    </span>
                  )}
                </div>

                <div className="form-group text-center">
                  <button type="submit" className="btn btn-dark">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordSetup;
