import React from "react";
import "../Styles/ChangeLog.css";
// import starComment from "../Assets/icons8-very-popular-topic-30.png";
import { FaAtlas } from "react-icons/fa";
// import reportImg from "../Assets/log1.png";
// import testlab from "../Assets/icons8-test-tube-25.png";
import { FaUserTie } from "react-icons/fa";
// import user from "../Assets/userProfile.jpg";
import { FaTag } from "react-icons/fa";
import { FaJira } from "react-icons/fa";
import { FaRocket } from "react-icons/fa6";
// import quickActions from "../Assets/quickactions.jpg"
// import beta from "../Assets/beta.png"
// import alpha from "../Assets/testcase.png"
import { FaGear } from "react-icons/fa6";
import { MdSpaceDashboard } from "react-icons/md";
import { GrTest } from "react-icons/gr";
import { FaCommentDots } from "react-icons/fa6";




const ChangeLog = () => {
  return (
    <div style={{ marginTop: "4em" }}>
      <div style={{ textAlign: "center" }}>
        <h1>Change Log</h1>
        <p style={{ textAlign: "center" }}>
          We are constantly working on improving FrothTestOps to make it even
          better to serve your testing needs. This changelog keeps you informed
          about the latest updates and improvements made to the platform.
        </p>
        <hr style={{ marginBottom: "2em" }}></hr>
      </div>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <h5>What's New</h5>
        <i>Let’s See the Recent Releases</i>

        {/* Version 3.2 */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />

            <span className="main-topic">TestOps Version 3.2</span>
            {/* <button className="btn log-btn">20 July 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
          <div className="log-1 mb-4">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>

            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
              Improvement
              </button>
              <div style={{ textAlign: "justify" }}>
                <span>
                  Added "Go Back" button to the view page for better navigation
                </span></div>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
              Improvement
              </button>
              <div style={{ textAlign: "justify" }}>
                <span>
                  Fixed overlapping project dropdown in mobile view. </span>
              </div>
            </div>

            {/* <img className="col-8 mb-3" src={reportImg} alt="report logo" /> */}
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
            Improvement
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Default permissions are now automatically checked for new roles.            </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
            Improvement
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Restricted deletion of integrations when projects are mapped.</span>            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
            Improvement
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Ensured tags created for projects display correctly in dropdowns.</span>
            </div>
          </div>

          <hr className="col-12 mb-4"></hr>

          <div className="log-1">
            <button type="button" className="btn btn-enhance d-flex mb-4">
              Bug fixes
            </button>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Addressed various UI inconsistencies in the test plan sign-off page and left sidebar.           </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Updated the UI for the Global Dashboard by hiding the project selection dropdown .</span>            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <span style={{ textAlign: "left" }}>
                Resolved the issue of deleted projects still appearing in dropdowns after page refresh. </span>
            </div>
          </div>
        </div>
        <hr style={{ color: "black" }}></hr>

        {/* Version 3.1 */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />

            <span className="main-topic">TestOps Version 3.1</span>
            {/* <button className="btn log-btn">20 July 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="log-1">
              <GrTest />
              <span className="sub-mainTopic">Test Lab</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <div style={{ textAlign: "justify" }}>
                <b>Test Plan Signoff: </b>
                <span>
                  Introduced a comprehensive test plan detailing scope, milestones, and deliverables, along with execution details and a review process for stakeholder approvals.
                </span></div>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <div style={{ textAlign: "justify" }}>
                <b>Request approval in Test Plan: </b>
                <span>
                  Added a feature where Users can now request stakeholder approvals for finalized test plans.               </span>
              </div>
            </div>

            {/* <img className="col-8 mb-3" src={reportImg} alt="report logo" /> */}
          </div>
          <hr className="col-12 mb-4"></hr>

          <div className="log-1">
            <MdSpaceDashboard />
            <span className="sub-mainTopic">Dashboard  </span>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Project & Global Dashboard Enhancements: </b>
              <span>
                Improved project statistics and detailed representations on both the project and global dashboards for better insights.            </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Workflow: </b>
              <span>
                Enhanced approval workflows with in-app and email notifications to streamline stakeholder communications.             </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="log-1">
            <button type="button" className="btn btn-enhance d-flex mb-4">
              Bug fixes
            </button>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>User login Enhancement: </b>
              <span style={{ textAlign: "left" }}>
                Users can now log in to the TestOps platform using their registered domain from the product page.
              </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>User creation and License validation: </b>
              <span style={{ textAlign: "left" }}>
                Feature Considering the User creation limit and procured licence for the Product, System will Show Error When User Limit Exceeded and Duration of Subscription.            </span>
            </div>
          </div>
        </div>
        <hr style={{ color: "black" }}></hr>


        {/* Version 3.0 */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />
            <span className="main-topic">TestOps Version 3.0</span>
            {/* <button className="btn log-btn">20 July 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="log-1">
              <GrTest />
              <span className="sub-mainTopic">Test Lab</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Browser Stack Upload app:</b>
              <span>
                Implemented a new navigation path for uploading apps from Test Lab.
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <div style={{ textAlign: "justify" }}>
                <b>Agent Creation: </b>
                <span>
                  Enhanced the agent creation page with OS-specific instructions for seamless agent creation,<br /> streamlining test execution processes.
                </span>
              </div>
            </div>
            {/* <img className="col-8 mb-3" src={reportImg} alt="report logo" /> */}
          </div>
          <hr className="col-12 mb-4"></hr>

          <div className="log-1">
            <FaGear />
            <span className="sub-mainTopic">Settings </span>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>GitHub Repository Management: </b>
              <span>
                Enhanced mapping interface for creating GitHub repositories. During map project provided create repository option.
              </span>
            </div>
          </div>

        </div>
        <hr style={{ color: "black" }}></hr>

        {/* Version 2 */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />
            <span className="main-topic">TestOps Version 2.0</span>
            {/* <button className="btn log-btn">20 July 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="log-1">
              <FaAtlas />
              <span className="sub-mainTopic">Reports</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Defect Reports:</b>
              <span>
                Introduced a new module to generate and manage defect reports.
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Test Case Reports:</b>
              <span>
                Added functionality to create comprehensive test case reports
                for better analysis and tracking.
              </span>
            </div>
            {/* <img className="col-8 mb-3" src={reportImg} alt="report logo" /> */}
          </div>
          <hr className="col-12 mb-4"></hr>

          <div className="log-1">
            <GrTest />

            <span className="sub-mainTopic">Test Lab</span>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Database Integration for Validation in Automation: </b>
              <span>
                Enabled database integration for validation during automated
                testing. This feature allows for real-time querying and
                validation during script execution.
              </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Query and Assert During Script Preparation: </b>
              <span>
                Added the ability to form queries and perform assertions during
                script preparation, ensuring successful execution and validation
                of test cases.
              </span>
            </div>
          </div>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Test Data Import: </b>
              <span>
                Users can now import test data in .json format directly,
                eliminating the need to input data manually.
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="log-1">
            <FaUserTie />
            <span className="sub-mainTopic">User Profile</span>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div className="reports-content mb-4">
            <button type="button" class="btn btn-add">
              Added
            </button>
            <div style={{ textAlign: "justify" }}>
              <b>Profile Management: </b>
              <span>
                Introduced a user profile section where users can view their
                profile details and update their passwords.
              </span>
            </div>
          </div>
          {/* <img className="col-8 mb-3" src={user} alt="report logo" /> */}
          <hr className="col-12 mb-4"></hr>
          <div>
            <button type="button" className="btn btn-enhance d-flex mb-4">
              Enhancements
            </button>
            <div className="log-1">
              <FaTag />
              <span className="sub-mainTopic">Tags</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <div style={{ textAlign: "justify" }}>
                <b>Custom Dropdown Value: </b>
                <span>
                  Added a Tags feature in settings, allowing users to manage
                  custom dropdown values based on project requirements.
                </span>
              </div>
            </div>
            <div className="log-1">
              <FaJira />
              <span className="sub-mainTopic">JIRA and Plane Integration</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <div style={{ textAlign: "justify" }}>
                <b>Two-Way Integration: </b>
                <span>
                  Enabled two-way integration with JIRA and Plan, facilitating
                  seamless defect tracking and management across platforms.
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ color: "black" }}></hr>

        {/* Version 1 */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />
            <span className="main-topic">TestOps Version 1.0</span>
            {/* <button className="btn log-btn">27 June 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>1. Plane Integration for Defect Creation and Tracking</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Improvement
              </button>
              <span style={{ textAlign: "left" }}>
                Seamlessly create and track defects directly from TestOps with
                our new Plane integration. This feature ensures better defect
                management and streamlined communication within your testing
                process.
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>2. GitHub Configuration in Settings:</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Improvement
              </button>
              <span style={{ textAlign: "left" }}>
                Link your GitHub repositories to TestOps easily through the
                settings. This integration allows you to sync your codebase and
                test plans effortlessly, promoting a smoother workflow and
                continuous integration.
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>3. BrowserStack Configuration in Settings:</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Improvement
              </button>
              <span style={{ textAlign: "left" }}>
                Integrate your BrowserStack account with TestOps to automate
                execution across different browsers and devices. This feature
                simplifies cross-browser testing and ensures your applications
                perform flawlessly on various platforms.
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>4. Test Data Preparation and Integration:</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Improvement
              </button>
              <span style={{ textAlign: "left" }}>
                Upload your test data in JSON format and execute your tests with
                various data sets. This feature enhances the flexibility and
                accuracy of your testing by allowing you to prepare and
                integrate test data efficiently.
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>5. Quick Actions:</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Improvement
              </button>
              <span style={{ textAlign: "left" }}>
                Navigate and perform tasks faster with our new quick actions
                feature. Create features, manage configurations, and execute
                tests with a single click, saving you valuable time and effort.
              </span>
            </div>
            <div className="sub-imporovements ml-5">
              <hr className="col-12 mb-4"></hr>
              <div>
                <div className="d-flex gap-2 mb-4">
                  <button type="button" className="btn btn-improve d-flex">
                    Improvements
                  </button>
                  <b>1. Device Selection from Device Farm:</b>
                </div>
                <div className="log-1">
                  <FaRocket />
                  <span className="sub-mainTopic">Key features include:</span>
                </div>
                <hr className="col-12 mb-4"></hr>
                <div className="reports-content mb-4">
                  <button type="button" class="btn btn-added">
                    Added
                  </button>
                  <span style={{ textAlign: "left" }}>
                    During cycle creation, you can now select devices from the
                    device farm to execute tests on any device you need. This
                    improvement provides more control and flexibility over your
                    testing environment.
                  </span>
                </div>
              </div>
            </div>
            <div className="sub-imporovements ml-5">
              <hr className="col-12 mb-4"></hr>
              <div>
                <div className="d-flex gap-2 mb-4">
                  <button type="button" className="btn btn-improve d-flex">
                    Improvements
                  </button>
                  <b>2. Login with Security Puzzle:</b>
                </div>
                <div className="log-1">
                  <FaRocket />
                  <span className="sub-mainTopic">Key features include:</span>
                </div>
                <hr className="col-12 mb-4"></hr>
                <div className="reports-content mb-4">
                  <button type="button" class="btn btn-added">
                    Added
                  </button>
                  <span style={{ textAlign: "left" }}>
                    To enhance security, we have added a security puzzle to the
                    login process. This added layer of protection ensures that
                    your account remains secure and protected from unauthorized
                    access.{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="sub-imporovements ml-5">
              <hr className="col-12 mb-4"></hr>
              <div>
                <div className="d-flex gap-2 mb-4">
                  <button type="button" className="btn btn-improve d-flex">
                    Improvements
                  </button>
                  <b>3. Email Notifications:</b>
                </div>
                <div className="log-1">
                  <FaRocket />
                  <span className="sub-mainTopic">
                    Email notifications now enabled for various actions. You will receive notifications when:
                  </span>
                </div>
                <hr className="col-12 mb-4"></hr>
                <div className="reports-content mb-4">
                  <button type="button" class="btn btn-added">
                    Added
                  </button>
                  <span style={{ textAlign: "left" }}>
                    A user is added to TestOps.
                  </span>
                </div>
                <div className="reports-content mb-4">
                  <button type="button" class="btn btn-added">
                    Added
                  </button>
                  <span style={{ textAlign: "left" }}>
                    A test case is assigned to a user.
                  </span>
                </div>
                <div className="reports-content mb-4">
                  <button type="button" class="btn btn-added">
                    Added
                  </button>
                  <span style={{ textAlign: "left" }}>
                    A user initiates a password reset.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <img className="col-8 mb-3" src={quickActions} alt="report logo" /> */}
        </div>
        <hr style={{ color: "black" }}></hr>

        {/* Beta Release */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />
            <span className="main-topic">Beta Release</span>
            {/* <button className="btn log-btn">02 May 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>Defect Management</b>
            </div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                Details
              </button>
              <b>A module for tracking and managing defects throughout the testing lifecycle.</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Defect reporting and tracking
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Severity and priority assignment
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Integration with JIRA tool
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          {/* <img className="col-8 mb-3" src={beta} alt="report logo" /> */}
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>Test Lab </b>
            </div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                Details
              </button>
              <b>Combine several sub-modules aimed at automating and managing test execution processes.</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Automation Script: </b>
              <span style={{ textAlign: "left" }}>
                Management and execution of automated test scripts
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Automation Suite: </b>
              <span style={{ textAlign: "left" }}>
                Grouping of automated test scripts for efficient execution
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Test Cycle: </b>
              <span style={{ textAlign: "left" }}>
                Planning and execution of test cycles
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Test Plan: </b>
              <span style={{ textAlign: "left" }}>
                Comprehensive planning for test cycles, including objectives, resources, and schedules
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <b>Test Cycle Execution: </b>
              <span style={{ textAlign: "left" }}>
                Real-time monitoring and reporting of test cycle progress
              </span>
            </div>
          </div>
        </div>
        <hr style={{ color: "black" }}></hr>

        {/* Alpha Release */}
        <div className="container col-md-10" style={{ margin: "4rem" }}>
          <div className="log-1" style={{ textAlign: "left" }}>
            <FaCommentDots size={25} />
            <span className="main-topic">Alpha Release</span>
            {/* <button className="btn log-btn">08 March 2024</button> */}
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>Project Management</b>
            </div>
            <div className="d-flex gap-2 mb-4 text-left">
              <button type="button" className="btn btn-new d-flex">
                Details
              </button>
              <b>A comprehensive module to streamline project workflows, and manage timelines, resources & deliverables.</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Project creation and configuration
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Resource allocation
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Requirement creation and configuration
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Module creation and configuration
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Build creation and configuration
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          {/* <img className="col-8 mb-3" src={alpha} alt="report logo" /> */}
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>Test Management </b>
            </div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                Details
              </button>
              <b>Provides a suite for planning, designing, and executing test cases.</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Test Scenario creation and management
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Test case creation and management
              </span>
            </div>
          </div>
          <hr className="col-12 mb-4"></hr>
          <div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                New Feature
              </button>
              <b>Settings </b>
            </div>
            <div className="d-flex gap-2 mb-4">
              <button type="button" className="btn btn-new d-flex">
                Details
              </button>
              <b>Allows administrators to configure and customize the application to meet organizational needs.</b>
            </div>
            <div className="log-1">
              <FaRocket />
              <span className="sub-mainTopic">Key features include:</span>
            </div>
            <hr className="col-12 mb-4"></hr>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                User management
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                Role-based access control
              </span>
            </div>
            <div className="reports-content mb-4">
              <button type="button" class="btn btn-add">
                Added
              </button>
              <span style={{ textAlign: "left" }}>
                System integration and configuration
              </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ChangeLog;
