import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import ProfImg from "../Assets/ava3.png";
import PropTypes from "prop-types";
import { Check, Label } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import PrintIcon from "@mui/icons-material/Print";
// import ReplayIcon from '@mui/icons-material/Replay';
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentsIcon from "@mui/icons-material/Payments";
import ActiveUserPng from "../Assets/active-user.png";
import UserPng from "../Assets/user.png";
import { apiUrl } from "../Pages/Config";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import tagImg from "../Assets/tag-img.jpg";
import cameraImg from "../Assets/camera.png";
import Chip from "@mui/joy/Chip";
import { ListItemDecorator } from "@mui/joy";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import CardActions from '@mui/joy/CardActions';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const getData = async (setProfileData, setproductData, setInvoiceData, setPlanDetailsArray, setRenewActive) => {
  const logId = localStorage.getItem("Id");
  const accessToken = localStorage.getItem("accessToken");
  // console.log("Hai i am running from function...",logId);
  if (logId && accessToken) {
    try {
      const response = await fetch(apiUrl + "getSspUserDetails/" + logId, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const myAccountData = await response.json();

      setProfileData(myAccountData.customerDetails);
      setproductData(myAccountData.productDetails);
      const planDetailsArray = myAccountData.productDetails.plan_details.split('|').map(detail => detail.trim());
      setPlanDetailsArray(planDetailsArray);

      const invoiceDataArr = Object.values(myAccountData.customerInvoiceDetails).map((invoice, index) => ({
        S_No: index + 1,
        date: formatDate(invoice.invoiceDate),
        Invoice: invoice.invoiceNumber, // You can customize the invoice number format
        plan: invoice.planType,
        price: "99.00",
        invoicePdfUrl: invoice.invoicePdfUrl
      }));
      setInvoiceData(invoiceDataArr);
      const today = new Date();
      const domainExpiry = new Date(myAccountData.productDetails.due_date);
      const diffTime = domainExpiry.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log('diffDays--',diffDays);
      if (diffDays <= 7) {
        setRenewActive(false);
      }

    } catch (error) {
      console.log("error", error);
    }
  }
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Myaccount() {
  const [profileData, setProfileData] = useState([null]);
  const [productData, setproductData] = useState([null]);
  const [invoiceData, setInvoiceData] = useState();
  const [planData, setPlanData] = useState();
  const [planDetailsArray, setPlanDetailsArray] = useState([]);
  const [renewActive, setRenewActive] = useState(true);
  const navigate = useNavigate();


  const [value, setValue] = React.useState(0);
  useEffect(() => {
    getData(setProfileData, setproductData, setInvoiceData, setPlanDetailsArray, setRenewActive);
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renewNow = () => {
    console.log("tester...");
    // navigate(invoiceData);
    window.open("/Pricing", "_blank");
  };


  // console.log('invoiceData--', invoiceData);
  const columns = [
    {
      name: "S_No",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "Invoice",
      label: "Invoice",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "date",
      label: "Invoice Date",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          const rowData = invoiceData[tableMeta.rowIndex];
          const invoicePdfUrl = rowData.invoicePdfUrl;


          return (
            <>
              <Button
                title="Print Invoice"
                sx={{ fontWeight: 500 }}
                variant="outline"
                size="small"
                onClick={() => window.open(invoicePdfUrl, '_blank')}
              >
                <PrintIcon size="small" />
              </Button>
            </>
          );
        },
        setCellProps: () => ({
          style: { textAlign: 'center' }
        })
      }

    }
  ];



  const addcolumns = [
    {
      name: "S_No",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "Plan",
      label: "Plan",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "Start Date",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "End Date",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },
    {
      name: "Users",
      label: "Users",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }
    },

    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
      }

    }
  ];


  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPage: 5,
    download: true,
    print: true
  };


  return (
    <>
      <Grid className="profile-page-container" container>
        <Grid container sx={{ m: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" to="/Self-Service">
              Self Service
            </Link>
            <Typography sx={{ color: "text.primary" }}>My Account</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container className="tag-div">
          <img src={tagImg} className="tag-img" />
        </Grid>

        <Grid item xs={6} md={3} lg={3} xl={3} sm={4}>
          <Card
            className="profile-card"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography component="div" className="profile-card-image">
              <Typography component="div" className="prof-img-bg" >
                <Avatar
                  src={UserPng}
                  alt="Profile Image"
                  sx={{
                    width: 100,
                    height: 100,
                    m: 3,
                    background: "#eeeeee",
                    zIndex: 1,
                  }}
                />
              </Typography>
              <Typography component="div" className="prof-camera-bg">
                <img className="prof-camera" src={cameraImg} width="20px" />
              </Typography>
            </Typography>
            <Typography sx={{ mt: "-5px", fontWeight: "bold" }} variant="h6">
              {profileData.name}
            </Typography>
            <Typography variant="p">{profileData.organization_name}</Typography>

            <Divider sx={{ width: "100%" }} />
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.email}
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.phone_office}
            </Typography>
            <Typography sx={{ fontWeight: 500, mt: 1, mb: 1 }} variant="p">
              {profileData.product_name}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={9} lg={9} xl={9} sm={4}>
          <Card
            className="profile-progress-card"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab className="prof-tab-heading" label="My Account" {...a11yProps(0)} />
                  <Tab className="prof-tab-heading" label="Plan Details" {...a11yProps(1)} />
                  <Tab className="prof-tab-heading" label="Billing Details" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  component="form"
                  sx={{ "& > :not(style)": { m: 1, width: "40ch" } }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-name"
                    label="Name"
                    variant="outlined"
                    value={profileData.name}
                    InputLabelProps={
                      profileData.name ? {
                        shrink: true,
                      } : ''}
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <TextField
                    id="outlined-email"
                    label="Email"
                    variant="outlined"
                    value={profileData.email}
                    InputLabelProps={
                      profileData.email ? {
                        shrink: true,
                      } : ''}
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />
                  <TextField
                    id="outlined-phone"
                    label="Phone"
                    variant="outlined"
                    value={profileData.phone_office}
                    InputLabelProps={
                      profileData.phone_office ? {
                        shrink: true,
                      } : ''}
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />


                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    value={profileData.organization_name}
                    InputLabelProps={
                      profileData.organization_name ? {
                        shrink: true,
                      } : ''}
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Total Users"
                    variant="outlined"
                    value={productData.number_of_users}
                    InputLabelProps={
                        {shrink: true,}
                    }
                    InputProps={{
                      readOnly: true, // Make the field read-only
                    }}
                  />


                  <Box
                    container
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                      },
                    }}
                  >
                    {/* <Button sx={{ backgroundColor: '#ff0000', fontWeight: 600 }} variant="contained">Update</Button> */}
                  </Box>
                </Box>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid"

                  }}
                >
                  <Card size="lg" variant="outlined">
                    <Typography component="div" sx={{ display: 'flex', gap: '531px' }} >
                      <Chip className="plan-card-title" size="sm" variant="outlined" color="neutral">
                        Current Package : {productData.plantype}
                      </Chip>
                      <Chip className="plan-card-title" size="sm" variant="outlined" color="neutral">

                        Expiry {formatDate(productData.due_date)}
                      </Chip>
                    </Typography>
                    <Typography sx={{ ml: '15px', mt: "2px", mb: "2px" }} level="h2"> Features </Typography>
                    <Divider inset="no ne" />
                    <Box container  >
                      <Grid container sx={{ display: 'flex', margin: '5px' }} >
                        {planDetailsArray.map((detail, index) => (
                          <Grid key={index} item xs={6} md={6} lg={4} xl={4} sm={6} >
                            <ListItem sx={{ lineHeight: '2' }} >
                              <ListItemDecorator>
                                <Check size="small" />
                              </ListItemDecorator>
                              {detail}
                            </ListItem>
                          </Grid>
                        ))}

                      </Grid>
                    </Box>
                    <Divider inset="none" />
                    <CardActions>
                      <Typography container component="div" sx={{ display: 'flex', gap: '631px', margin: '10px', alignItems: 'center' }} >
                        <Typography component="div"  >
                          <Typography variant="p" sx={{ fontWeight: 900 }} >
                            $ {productData.monthly_cost > 0 ? productData.monthly_cost + ' / month' : productData.yearly_cost + ' / year'}
                          </Typography>
                        </Typography>

                        <Typography component="div" className="plan-buttons" sx={{ display: 'flex', gap: '8px' }} >

                          <Button disabled={renewActive} onClick={renewNow} title="Renew" sx={{ backgroundColor: '#ff0000', fontWeight: 500 }} variant="contained" size="small" >
                            Renew now {renewActive}
                          </Button>

                        </Typography>
                      </Typography>

                    </CardActions>
                  </Card>

                  <Card sx={{ mt: '10px' }} className="mt" size="lg" variant="outlined">
                    <MUIDataTable
                      title={"Plan Details"}
                      data={planData}
                      columns={addcolumns}
                      options={options}
                    />
                  </Card>
                </Box>

              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid"

                  }}
                >
                  <Card size="lg" variant="outlined">
                    <MUIDataTable
                      title={"Billing Logs"}
                      data={invoiceData}
                      columns={columns}
                      options={options}
                    />
                  </Card>
                </Box>
              </CustomTabPanel>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Myaccount;
