import React, { useState, useEffect } from "react";
import { apiUrl } from "../Pages/Config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoading } from "../Pages/Store";
import SuccessPage from "./SuccessPage";

const SignUp = () => {
  const dispatch = useDispatch()
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [isOrgEmailValid, setIsOrgEmailValid] = useState(false);
  const [isDomainValid, setIsDomainValid] = useState(false);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formCheckErrors, setFormCheckErrors] = useState({});
  const [organizationName, setOrganizationName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { priceData } = useSelector((state) => state.root);
  const {
    showSuccessPage: showSuccessPage,
  } = useSelector((state) => state.root);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [isToggled, setIsToggled] = useState(priceData?.isToggled);
  const [isRecurring, setIsRecurring] = useState(false); // For recurring payments

  const BuyPlan = localStorage.getItem("BuyPlan");
  const [numberOfUsers, setNumberOfUsers] = useState(10);

  const handleIncrement = () => {
    setNumberOfUsers(numberOfUsers + 10);
  };

  const handleDecrement = () => {
    setNumberOfUsers((prevNumber) => {
      const newValue = prevNumber - 10;
      return newValue < 10 ? 10 : newValue;
    });
  };

  const handleCheckout = async () => {
    if (!validateCheckOut()) return;
    dispatch(setLoading(true))
    const payload = {
      organization_name: organizationName,
      first_name: firstName,
      last_name: lastName,
      email_id: organizationEmail,
      domain: domain + ".frothtestops.com",
      number_of_users: numberOfUsers,
      product_name: "FrothTestops",
      plan_type: priceData?.plan,
      payment_type: isToggled ? "Yearly" : "Monthly",
      yearly_recurring: isRecurring ? 1 : 0,
      monthly_cost: totalBillingPrice,
      yearly_cost: isToggled ? monthlyBillingPrice : 0,
      per_month_cost_per_user:isToggled ?priceData?.yearly : priceData?.monthly,
    };

    const response = await fetch(apiUrl + "registrationCreateCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data);
      toast.success(data?.message || "Customer registered successfully", {
        position: "top-right",
        autoClose: 2000,
      });
      if(data.success === true)
        {
            payload['customer_scrm_id'] = data.customer_scrm_id;
            payload['customer_stripepg_id'] = data.customer_stripepg_id;
            payload['customer_pgproductid'] = data.customer_pgproductid;
            if (payload.customer_scrm_id && payload.customer_scrm_id.trim() !== "") {
                const checkout_response = await fetch('https://frothtestops.com/checkout.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                    body: JSON.stringify(payload),
                });
                const res = await checkout_response.json();
                
                if (res.status === "success") {
                    window.open(res.location, '_self');
                } else {
                    alert('Something Went Wrong!');
                }
            }
        } else {
            alert('Something Went Wrong!');
        }
      setIsCheckedOut(false);
    } else {
      toast.warn("Something went wrong", {
        position: "top-right",
        autoClose: 2000,
      });
      setIsCheckedOut(false);
    }
    dispatch(setLoading(false))
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
    setIsRecurring(false);
  };

  
  useEffect(() => {
    if (!BuyPlan) {
      handleBack();
    } else if (Object.keys(priceData).length === 0) {
      handleBack();
    }
  }, [BuyPlan, priceData]);

  const handleBack = () => {
    navigate("/Pricing");
    localStorage.removeItem("BuyPlan");
  };

  useEffect(() => {
    // Update isCheckedOut based on email and domain validity
    if (organizationEmail && domain) {
      setIsCheckedOut(isOrgEmailValid && isDomainValid);
    } else {
      setIsCheckedOut(false);
    }
  }, [isOrgEmailValid, isDomainValid, domain, organizationEmail]);

  useEffect(() => {
    const button = document.getElementById("validate-button");
    if (button) {
      button.style.visibility = isCheckedOut ? "hidden" : "visible";
    }
  }, [isCheckedOut]);

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const commonDomains = ["yahoo.com", "gmail.com", "microsoft.com"];

    if (!organizationEmail?.trim()) {
      errors.organizationEmail = "Organization Email required";
    } else if (!emailRegex.test(organizationEmail)) {
      errors.organizationEmail = "Valid Organization Email required";
    } else {
      const emailDomain = organizationEmail.split("@")[1];
      if (commonDomains.includes(emailDomain)) {
        errors.organizationEmail = "Valid Organization Email required";
      }
    }

    if (!domain?.trim()) {
      errors.domain = "Domain required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateCheckOut = () => {
    const errors = {};
    if (!organizationName?.trim())
      errors.organizationName = "Organization Name required";
    if (!firstName?.trim()) errors.firstName = "First Name required";
    if (!lastName?.trim()) errors.lastName = "Last Name required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateOrganizationEmail = async () => {
    if (!validateForm()) return;

    dispatch(setLoading(true))
    const response = await fetch(
      `${apiUrl}registrationEmailValidation/${organizationEmail}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + `${localStorage.getItem("accessToken")}`,
        },
      }
    );
    const orgRes = await response.json();
    setIsOrgEmailValid(orgRes.emailflag === "1");
    // Reset validation status after checking
    if (orgRes.emailflag !== "1") {
      setIsOrgEmailValid(false);
      toast.warn(orgRes?.message || "Something went wrong", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    dispatch(setLoading(false))
  };

  const validateDomain = async () => {
    if (!domain) return;

    dispatch(setLoading(true))

    const fullDomain = `${domain}.frothtestops.com`;
    const response = await fetch(
      `${apiUrl}registrationFormDomainValidation/${fullDomain}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + `${localStorage.getItem("accessToken")}`,
        },
      }
    );
    const domRes = await response.json();
    setIsDomainValid(domRes.domainflag === "1");
    // Reset validation status after checking
    if (domRes.domainflag !== "1") {
      toast.warn(domRes?.message || "Something went wrong", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    dispatch(setLoading(false))
  };

  const handleInputChange = (setter, validator) => (e) => {
    setter(e.target.value);
    validator(); // Trigger validation
    setIsCheckedOut(false); // Ensure button is visible when input changes
  };

  const totalBillingPrice =
    (isToggled ? priceData?.yearly : priceData?.monthly) * numberOfUsers;
  const formattedTotalBillingPrice = parseFloat(totalBillingPrice).toFixed(2); // 2 decimal places

  const monthlyBillingPrice = (
    (isToggled ? 12 : 1) * totalBillingPrice
  ).toFixed(2); // 2 decimal places

  return (
    <div
      className="container row"
      id="contact-page"
      style={{ marginLeft: "0.67rem" }}
    >
      <div className="form-container col-md-6" id="form-container">
        <h2 className="mb-4">
          <i
            className="fas fa-arrow-left me-2"
            id="back-btn"
            onClick={handleBack}
            style={{ color: "#FF0000" }}
          ></i>
          Sign Up
        </h2>
        <p>Create an account to continue</p>
        <form>
          {/* Organization Name Input */}
          <div className="form-group">
            <label htmlFor="organizationName">
              Organization Name <span className="required">*</span>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-building"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="organizationName"
                placeholder="Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </div>
            {formErrors.organizationName && (
              <span style={{ color: "#FF0000" }}>
                {formErrors.organizationName}
              </span>
            )}
            <span id="org-namemsg" style={{ color: "#FF0000" }}></span>
          </div>

          {/* First Name Input */}
          <div className="row g-3">
            <div className="col-md-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name <span className="required">*</span>
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-light fa-user"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              {formErrors.firstName && (
                <span style={{ color: "#FF0000" }}>{formErrors.firstName}</span>
              )}
              <span id="first-namemsg" style={{ color: "#FF0000" }}></span>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name <span className="required">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              {formErrors.lastName && (
                <span style={{ color: "#FF0000" }}>{formErrors.lastName}</span>
              )}
            </div>
          </div>

          {/* Organization Email Input */}
          <div className="mb-3">
            <label htmlFor="organizationEmail" className="form-label">
              Organization Email <span className="required">*</span>{" "}
              <i
                id="validateOrgEmail"
                className="fa-regular fa-circle-check"
                style={{
                  color: "#61c961",
                  visibility: isOrgEmailValid ? "visible" : "hidden",
                }}
              ></i>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-envelope"></i>
                </span>
              </div>
              <input
                type="email"
                className="form-control"
                id="organizationEmail"
                placeholder="Organization Email"
                value={organizationEmail}
                onChange={handleInputChange(setOrganizationEmail, () => {
                  setIsOrgEmailValid(false);
                  setIsCheckedOut(false);
                })}
              />
            </div>
            {formErrors.organizationEmail && (
              <span style={{ color: "#FF0000" }}>
                {formErrors.organizationEmail}
              </span>
            )}
          </div>

          {/* Domain Input */}
          <div className="mb-3">
            <label htmlFor="shortCode" className="form-label">
              Domain <span className="required">*</span>{" "}
              <i
                id="validateDomain"
                className="fa-regular fa-circle-check"
                style={{
                  color: "#61c961",
                  visibility: isDomainValid ? "visible" : "hidden",
                }}
              ></i>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-building"></i>
                </span>
              </div>
              <input
                style={{
                  borderBottomRightRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
                type="text"
                className="col-md-5 form-control"
                id="domain"
                placeholder="Domain"
                value={domain}
                onChange={handleInputChange(setDomain, () => {
                  setIsDomainValid(false);
                  setIsCheckedOut(false);
                })}
              />
              <span
                className="form-label"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "3%",
                }}
              >
                .frothtestops.com
              </span>
              <button
                type="submit"
                id="validate-button"
                className="btn w-40 font-weight-bold"
                onClick={(e) => {
                  e.preventDefault();
                  validateOrganizationEmail();
                  validateDomain();
                }}
                style={{ visibility: "visible" }}
              >
                Validate
              </button>
            </div>
            {formErrors.domain && (
              <span style={{ color: "#FF0000" }}>{formErrors.domain}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="numberOfUsers" className="form-label">
              Number of Users <span className="required">*</span>
            </label>
            <div className="input-group" id="input-group">
              <button
                id="decrement"
                type="button"
                onClick={handleDecrement}
                disabled={numberOfUsers <= 10}
              >
                <i className="fas fa-minus"></i>
              </button>
              <input
                type="text"
                style={{ width: "4em" }}
                id="numberOfUsers"
                value={numberOfUsers}
                readOnly
              />
              <button id="increment" type="button" onClick={handleIncrement}>
                <i className="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="form-container col-md-6" id="form-container">
        <h2 className="mb-3">Order Summary</h2>
        <div
          className="form-check form-switch mb-2"
          style={{
            paddingLeft: "0rem",
            display: "flex",
            gap: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            className="form-check-label ms-2"
            for="billing-toggle"
            style={{ color: "#ff0000", fontSize: "18px" }}
          >
            {isToggled ? "Yearly" : "Monthly"} Subscription
          </label>
          <label className="switch" style={{ width: "53px" }}>
            <input
              type="checkbox"
              id="billing-toggle"
              checked={isToggled}
              onChange={handleToggle}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="cart-card-content">
          <div
            className="card align-center col-10"
            style={{ padding: "0", margin: "0" }}
          >
            <div className="card-header" id="planTitle">
              {priceData?.plan}
            </div>
            <div className="card-body" id="cart-card">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div className="input-group-prepend">
                      <span style={{ width: "30px", borderRight: "1px solid" }}>
                        <i className="fas fa-users"></i>
                      </span>
                    </div>
                    <div>Users</div>
                  </div>
                  <div>
                    <div>
                      <sup style={{ top: "-0.3em" }}>$</sup>
                      <span className="billing-price">
                        {isToggled ? priceData?.yearly : priceData?.monthly}
                      </span>
                      /month for <span id="usersNo">{numberOfUsers}</span> users
                    </div>
                  </div>
                </div>
                <div>
                  <sup style={{ top: "-0.3em" }}>$</sup>
                  <span
                    className="total-billing-price"
                    id="total-billing-price"
                  >
                    {formattedTotalBillingPrice}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3.5rem",
                  alignItems: "center",
                  borderBottom: "1px solid",
                  borderTop: "1px solid",
                }}
              >
                <div>
                  <sup style={{ top: "-0.3em" }}>$</sup>
                  <span className="total-billing-price">
                    {formattedTotalBillingPrice}
                  </span>
                  &nbsp;*&nbsp;
                  <span id="monthCount">
                    {isToggled ? "12 months" : "1 month"}
                  </span>
                </div>
                <div>
                  <sup style={{ top: "-0.3em" }}>$</sup>
                  <span
                    className="monthly-billing-price"
                    id="monthly-billing-price"
                  >
                    {monthlyBillingPrice}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  id="totalDue"
                >
                  <div>
                    <h3>Total Due</h3>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <sup>$</sup>
                    <h3>
                      <span className="monthly-billing-price">
                        {monthlyBillingPrice}
                      </span>
                    </h3>
                  </div>
                </div>
                {isRecurring && (
                  <hr style={{ color: "black", opacity: "1" }}></hr>
                )}
                {isToggled && (
                  <>
                    <div
                      id="recurring"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2%",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="checkRec"
                        value=""
                        aria-label="Recurring Payment"
                        checked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                      />
                      <span>&nbsp;Recurring Payment</span>
                    </div>
                  </>
                )}
                {isRecurring && (
                  <div
                    id="recurring-price"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2%",
                    }}
                  >
                    <span>Monthly commitment</span>
                    <span>
                      <sup style={{ top: "-0.3em" }}>$</sup>
                      <span className="total-billing-price">
                        {formattedTotalBillingPrice}
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isToggled ? (
            <div
              className="col-10"
              id="monthYearContainer"
              style={{
                padding: "10px",
                backgroundColor: "rgb(207, 247, 211)",
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                justifyContent: "center",
                borderRadius: "10px",
                marginTop: "0.7rem",
                marginBottom: "0.7rem",
              }}
            >
              <span id="monthYearCard">
                Saved 20% by choosing Annual Subscription
              </span>
            </div>
          ) : (
            <div
              className="col-10"
              id="monthYearContainer"
              style={{
                padding: "10px",
                backgroundColor: "#DDF1FF",
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                justifyContent: "center",
                borderRadius: "10px",
                marginTop: "0.7rem",
                marginBottom: "0.7rem",
              }}
            >
              <span id="monthYearCard">Pay annually and save up to 20%</span>
            </div>
          )}
          <div
            className="w-100 col-10"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="button"
             id="buy-now"
                  className="btn"
                  style={{
                    backgroundColor: isCheckedOut ? '#ff0000' : '#ff000082',
                    cursor : isCheckedOut? 'pointer' : 'not-allowed'
                  }}
                  
              onClick={handleCheckout}
              disabled={isCheckedOut ? false : true}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
      {showSuccessPage && <SuccessPage />}    </div>
    
  );
};

export default SignUp;
