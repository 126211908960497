import React from 'react';
import '../Styles/terms.css';

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <h1>Privacy Policy</h1>
      <p>Robotico Digital (“we,” “us,” or “our”) is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our IT services through our website <a href="https://www.roboticodigital.com" target="_blank" rel="noopener noreferrer">https://www.roboticodigital.com</a> (“Website”).</p>
      <h4>1. Information We Collect</h4>
      <p>1.1. Personal Information We may collect personal information that you voluntarily provide to us when you use our services, such as your name, designation, company, email address, or phone number. If you are using any of our pages for submitting your resume for job related purpose, then you are voluntarily doing so, and as a result we may have your resume or related details.</p>
      <p>1.2. Usage Data We may also collect information about how you access and use our Website, including your IP address, browser type, pages visited, and time spent on our site.</p>
      <h4>2. How We Use Your Information</h4>
      <p>2.1. We may use the information we collect for various purposes, including to:</p>
      <ul>
        <li>Provide and maintain our services.</li>
        <li>Process transactions and fulfill requests.</li>
        <li>Communicate with you about our services and updates.</li>
        <li>Personalize your experience and improve our Website.</li>
        <li>Detect, prevent, and address technical issues.</li>
      </ul>
      <h4>3. Disclosure of Your Information</h4>
      <p>3.1. We may disclose your information to third parties, including:</p>
      <ul>
        <li>Service providers who assist us in operating our Website or providing our services.</li>
        <li>Law enforcement or government agencies in response to a valid legal request.</li>
      </ul>
      <h4>4. Data Security</h4>
      <p>4.1. We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
      <h4>5. Your Choices</h4>
      <p>5.1. You may choose not to provide certain information, but this may limit your ability to use some features of our Website.</p>
      <p>5.2. You can opt out of receiving promotional communications from us by following the instructions in those communications or by contacting us directly.</p>
      <h4>6. Third-Party Links</h4>
      <p>6.1. Our Website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these third-party sites.</p>
      <h4>7. Children’s Privacy</h4>
      <p>7.1. Our services are not directed to children under the age of 18, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete their information.</p>
      <h4>8. Changes to This Policy</h4>
      <p>8.1. We may update this Privacy Policy from time to time, and any changes will be posted on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>
      <h4>9. Contact Us</h4>
      <p>9.1. If you have any questions or concerns about this Privacy Policy, please write to us at <a style={{textDecoration:'none'}} href='mailto:info@roboticodigital.com'>info@roboticodigital.com</a> or refer to our contact page for more details.</p>
      <h4>10. No Unsolicited Calls Policy</h4>
      <p>10.1. We value our user's privacy and are committed to maintaining a respectful and considerate communication approach. We will not initiate phone contact with individuals unless they have registered as customers, submitted an application for employment opportunities, or raised a direct or indirect inquiry through our platform or channels.</p>

    </div>
  );
};

export default PrivacyPolicy;
